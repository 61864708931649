import React from 'react';
import Subtitle from '../../shared/Subtitle'
import BlueSection from '../../shared/BlueSection'
import Code from '../../shared/Code'
import CodeSection from '../../shared/CodeSection'

export function JS({codeLang}) {

    switch(codeLang) {
        case 'http': return <Code lang="javascript">
        {`
// Define el recuadro en tu template HTML

<div
    style="
        border: 1px solid black; 
        float: right; 
        width: 200px; 
        height: 120px; 
        margin: auto; 
        color: white; 
        word-break: break-all; 
        font-size: 2px;"
>
</div>

// Al momento de utilizarlo inyectas en ese campo
// los valores del sticker

Authorization signmage mBS73C5TdTjthjF0:HMAC
Content-Type application/json
POST /developers/json

{ 
    template_title: 'ejemploSticker', 
    fields: [
        {"id":"signer1","value":"Firmamex GOCF9002226A7 dibujo"},
    ],
    tables: [] 
}
        `}
        </Code>
        case 'python': return <Code lang="javascript">
                {`
from signmage import SignmageServices

webid = "mBS73C5TdTjthjF0"
apikey = "521026f468b9dadc2419e476356934d0"

services = SignmageServices(webid, apikey)
data = services.request({
"url_doc":"https://firmamex.com/pdf/simple/default"
})
                `}
                </Code>
        default: return <Code lang="javascript">
{`
// Define el recuadro en tu template HTML

<div
    style="
        border: 1px solid black; 
        float: right; 
        width: 200px; 
        height: 120px; 
        margin: auto; 
        color: white; 
        word-break: break-all; 
        font-size: 2px;"
>
</div>

// Al momento de utilizarlo inyectas en ese campo
// los valores del sticker

const response = await services.request({ 
    template_title: 'ejemploSticker', 
    fields: [
        {"id":"signer1","value":"Firmamex GOCF9002226A7 dibujo"},
    ],
    tables: [] 
})
`}
        </Code>
    }
}

export function html() {
    return(
        <React.Fragment>
            <Subtitle>Colocando un recuadro de firma en tu PDF</Subtitle>
            <div className="Content">
            <p>
                Cuando se recibe un PDF, Firmamex analiza <b>las primeras 3 y últimas 8 páginas*</b> del documento en busca de recuadros Firmamex.
                Un recuadro Firmamex es cualquier rectángulo que dentro tenga la palabra "Firmamex" seguida de QR o los datos del firmante
            </p>
            <p>
                Si la siguiente palabra es QR Firmamex elimina el recuadro y coloca un código QR con la liga actual del documento en su lugar
            </p>
            <p>
                Si después de Firmamex vienen los datos del firmante, Firmamex elimina estos rectángulos y coloca un área de firma
                en su lugar de manera automática
            </p>
            <BlueSection>
                <div>
                    Se pueden cambiar las páginas que se analizan por medio del API. Esto permite que agregues recuadros de firma a páginas que se
                    encuentran fuera del rango default y que el proceso sea más rápido, ya que sólo analiza las páginas necesarias. Puedes ver un ejemplo
                    al final de esta página.
                </div>
            </BlueSection>

            <p style={{fontWeight:"bold"}}>
                El formato para colocar áreas de firma es el siguiente: Firmamex [datos del firmante] [configuración de firma]
            </p>

            <ol>
                <li>
                    <p><strong>Datos del firmante</strong></p>
                    <p>Indica cómo se validará al firmante, tiene que ser uno de los siguientes <strong>tipos de dato</strong>:</p>
                    <BlueSection>
                        <div>
                            Tipos de datos: Las siguientes opciones indican sólo el tipo de dato, en el recuadro debe
                            incluirse el dato directamente, no el nombre de la opción. Por ejemplo, RFC: Firmamex VECJ880326XXX
                        </div>
                    </BlueSection>
                    <ol>
                        <li>
                            <strong>RFC</strong>: Para solicitar firma por SAT
                            <ol>
                                <li>
                                    Si es firma por SAT y deseas utilizar las invitaciones de firma de Firmaemx, debe incluir
                                    también un correo, para indicar a qué correo mandar la invitación
                                </li>
                            </ol>
                        </li>
                        <li>
                            <strong>Correo</strong>: Para solicitar firma por Correo
                            <ol>
                                <li>
                                    Por default utiliza la autoridad de Correo por Liga
                                </li>
                            </ol>
                        </li>
                        <li>
                            <strong>Teléfono</strong>: Número a 10 dígitos para solicitar firma por SMS
                            <ol>
                                <li>
                                    Por default utiliza la autoridad de SMS por Liga
                                </li>
                            </ol>
                        </li>
                    </ol>
                </li>
                <br/>
                <li>
                    <p><strong>Configuración de firma</strong></p>
                    <p>
                        Todas las siguientes son opcionales, configuran la manera en que se realiza la firma y deben
                        incluirse tal como se indican. Puede incluirse una opción de cada categoría
                    </p>
                    <ol>
                        <li>Opciones para la autoridad de firma</li>
                        <ol>
                            <li>
                                <strong>sso</strong>: Cambia la autoridad de Correo por Liga a Correo por Identity Provider
                            </li>
                            <li>
                                <strong>certificado</strong>: Cambia las autoridades de Correo por Liga a Correo y de
                                SMS por Liga a SMS
                            </li>
                            <li>
                                <strong>pin</strong>: Cambia las autoridades de Correo por Liga a Correo por PIN y de
                                SMS por Liga a SMS por PIN
                            </li>
                        </ol>
                        <li>Opciones para el proceso de firma</li>
                        <ol>
                            <li>
                                <strong>riv</strong>: Solicita verificación remota de identidad al firmante
                            </li>
                        </ol>
                        <li>Opciones para el dibujo de firma</li>
                        <ol>
                            <li>
                                <strong>desc</strong>: Utilizar tipo de imagen descriptiva
                            </li>
                            <li>
                                <strong>dibujo</strong>: Solicita al usuario que dibuje su trazo o lo suba como una
                                imagen en formato png
                            </li>
                            <li>
                                <strong>solo_dibujo</strong>: Solicita al usuario que dibuje su trazo
                            </li>
                            <li>
                                <strong>dibujo+desc</strong>: Solicita al usuario que dibuje su trazo o lo suba como una
                                imagen en formato png, e incluye un texto descriptivo debajo de la imagen
                            </li>
                            <li>
                                <strong>hash</strong>: Utiliza un hash del documento como imagen
                            </li>
                            <li>
                                <strong>name</strong>: Utiliza el nombre en el certificado del firmante escrito en
                                cursiva como imagen
                            </li>
                            <li>
                                <strong>desc_data</strong>: Texto que contiene el nombre del emisor del certificado, el
                                número de seria y el nombre del firmante en el certificado
                            </li>
                            <li>
                                <strong>qr</strong>: QR del documento, único para la firma
                            </li>
                        </ol>
                        <li>Opciones para la alineación del dibujo de firma</li>
                        <ol>
                            <li>
                                <strong>linea</strong>: Indica que el dibujo de firma debe de quedar en la parte inferior del recuadro
                            </li>
                            <li>
                                <strong>centrada</strong>: Cambia las autoridades de Correo por Liga a Correo por PIN y de SMS por Liga a SMS por PIN
                            </li>
                        </ol>
                        <li>Opciones para la ubicación de áreas de firma</li>
                        <ol>
                            <li>
                                paginas separadas por "-" o ",". Por ejemplo: 1-3,9. Indica en qué páginas debe aparecer el recuadro de firma (en la misma posición)
                            </li>
                        </ol>
                    </ol>
                </li>
            </ol>
            <p>
                <strong>Ejemplos:</strong>
            </p>
            <p>
                <strong>Firmamex firmante@correo.com</strong>
                <br/>
                Se enviará la invitación como firma por Correo por Liga, sin especificar trazo de firma
            </p>
            <p>
                <strong>Firmamex VECJ880326XXX firmante@correo.com dibujo</strong>
                <br/>
                Se enviará la invitación a firmante@correo.com para que firme con su certificado del SAT y dibujando su firma
            </p>
            <p>
                <strong>Firmamex 521234567890 pin name</strong>
                <br/>
                Se enviará la invitación como firma por SMS por PIN y con Nombre como trazo de firma
            </p>

            <br />
            <p>
                <a target="_blank" without rel="noopener noreferrer" href="https://storage.googleapis.com/static.firmamex.com/documentBoxes2.pdf">Descargar ejemplo PDF</a>
            </p>
            <p>
                <a target="_blank" without rel="noopener noreferrer" href="https://storage.googleapis.com/static.firmamex.com/documentBoxes.docx">Descargar ejemplo en documento de texto</a>
            </p>
            <br />
            </div>
            <Subtitle>Colocando un recuadro de firma en tu template HTML</Subtitle>
            <div className="Content">
            <p>
                Puedes colocar un recuadro en tu template HTML en la posición donde quieres
                que aparezca la firma. Al momento de solicitar tu documento, inyectas en este
                recuadro los datos del sticker como se pueden ver en el punto anterior
                (Colocando un recuadro de firma en tu PDF).
            </p>
            <p>
                1) Primero se define el recuadro como parte del <a href="/docs/documento/templates">Template</a> en el HTML.
            </p>
            <p>
                2) Después, al momento de utilizarlo inyectas en ese campo los valores del Sticker.
            </p>
            </div>
            <Subtitle>Especificando páginas para buscar áreas de firma</Subtitle>
            <div className="Content">

            <p>
                Por default se analizan las primeras 3 y las últimas 8 páginas de los documentos en búsqueda de áreas de firma. Si
                tu documento contiene áreas de firma en sólo una página o en páginas fueras del rango default, puedes especificar las páginas
                en las que necesitas que se realice la búsqueda agregando el parámetro <b>sticker_pages</b>
            </p>

            <p>Por ejemplo, el siguiente código especifica que se tienen que analizar las páginas 1, 3 y 5</p>

            <CodeSection lang="js" height={'220px'}>
            {
            `
                const response = await services.request({ 
                    template_title: 'ejemploSticker', 
                    sticker_pages: [0,2,4]
                    fields: [
                        {"id":"signer1","value":"Firmamex GOCF9002226A7 dibujo"},
                    ],
                    tables: [] 
                })`}
            </CodeSection>
            <p></p>
            <br/><br/>
            </div>
        </React.Fragment>
    )
}

export default { JS, html }

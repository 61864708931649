import React from 'react';
import Subtitle from '../../shared/Subtitle'
import Code from '../../shared/Code'
import CodeSection from '../../shared/CodeSection';

export function JS({codeLang}) {

    switch(codeLang) {
        case 'http': return <Code lang="javascript">
            {`
Authorization signmage mBS73C5TdTjthjF0:HMAC
Content-Type application/json

// Historial como PDF
GET /api/v1/document/{frmxId}/history/pdf

// Historial como JSON
GET /api/v1/document/{frmxId}/history/json

                `}
        </Code>
        default: return <Code lang="javascript">
            {`
const FirmamexServices = require('./firmamex');
const services = FirmamexServices('mBS73C5TdTjthjF0', "521026f468b9dadc2419e476356934d0");

const frmxId = '';

// Descarga como PDF
const pdf = await services.getHistoryAsPdf(frmxId);

// Descarga como JSON
const json = await services.getHistoryAsJson(frmxId);

`}
        </Code>
    }
}

export function Response() {
    return <Code lang="javascript">
        {
            `
// Para descarga como PDF, la respuesta es el PDF con Content-Type: application/pdf
%PDF-1.3
%????
7 0 obj
<<...

// Para descarga como JSON, la respuesta es Content-Type: application/json
[
    ... // arreglo de eventos
    {
        "documentEventType": "DOCUMENT_STAMPED", // nombre del evento
        "jsonMetaData": "{}" | null, // informacion adicional como JSON string o null
        "eventDate": 1711128805000, // fecha del evento 
        "createdAt": 1711128805000 // fecha del registro 
    }
]

            `
        }
    </Code>
}

export function html() {
    return (
        <React.Fragment>
            <Subtitle>Descargar historial</Subtitle>
            <div className="Content">
                <p>
                    El historial de un documento contiene información de la generación de un documento, envío,
                    seguimiento y firmas.
                    Puedes descargar esta información tanto en PDF como en JSON
                </p>
                <p>
                    Si realizas la descarga en JSON, el cuerpo de la respuesta tiene el siguiente formato:
                </p>
                <CodeSection lang="js">
                    {
                        `
[
    ... // arreglo de eventos
    {
        "documentEventType": "DOCUMENT_STAMPED", // nombre del evento
        "jsonMetaData": "{}" | null, // informacion adicional como JSON string o null
        "eventDate": 1711128805000, // fecha del evento 
        "createdAt": 1711128805000 // fecha del registro 
    }
]
`
                    }
                </CodeSection>
                <p>Los eventos pueden ser</p>
                <ul>
                    <li>
                        <strong>WHATSAPP_SENT</strong>: Se envió un mensaje de WhatsApp
                    </li>
                    <li>
                        <strong>WHATSAPP_DELIVERED</strong>: Se entregó un mensaje de WhatsApp
                    </li>
                    <li>
                        <strong>WHATSAPP_READ</strong>: Se leyó un mensaje de WhatsApp
                    </li>
                    <li>
                        <strong>EMAIL_FAILED</strong>: Falló una entrega de correo
                    </li>
                    <li>
                        <strong>EMAIL_OPENED</strong>: El destinatario abrió el correo
                    </li>
                    <li>
                        <strong>EMAIL_CLICKED</strong>: El destinatario hizo clic en el correo
                    </li>
                    <li>
                        <strong>EMAIL_DELIVERED</strong>: Se entregó un correo
                    </li>
                    <li>
                        <strong>FLOW_STARTED</strong>: Se inició el proceso de firma
                    </li>
                    <li>
                        <strong>NON_SIGNER_RECIPENT_FLOW_STARTED</strong>: Se notificó a un destinatario no firmante
                    </li>
                    <li>
                        <strong>SIGNATURE_INVITATION</strong>: Se envió una invitación para firma
                    </li>
                    <li>
                        <strong>DOCUMENT_STAMPED</strong>: Se selló el documento
                    </li>
                    <li>
                        <strong>DOCUMENT_COMPLETED</strong>: Se completó el documento
                    </li>
                    <li>
                        <strong>DOCUMENT_SIGNED</strong>: Se firmó el documento
                    </li>
                    <li>
                        <strong>DOCUMENT_CREATED</strong>: Se creó el documento
                    </li>
                </ul>
            </div>
        </React.Fragment>
    )
}

export default {JS, html, Response}
